import React  from 'react';
import './SelectParams.css';
import AddToCart from '../add-to-cart/AddToCart';
import TabContainer from '../tab-container/TabContainer';


function SelectParams() {
  return (
    <div className='select-params'>
      <TabContainer defaultSelectedValue={'Shape'}/>
      <AddToCart />
    </div>
  );
}

export default SelectParams;