import React  from 'react';
import { connect } from 'react-redux';
import { HandleFileService } from '../../services/HandleFileService';
import { FileProps } from '../models/FileProps';
import Reset from '../reset/Reset';
import './FileContainer.css';
import { updateApp } from '../../store/actions/appActions';
import { useState } from 'react';
import BackDrop from '../backdrop-spinner/BackDrop';
import ValidationError from '../validation-error/ValidationError';

function FileContainer(props: FileProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  
  const resetParams = () => {
   
  }

  const handleFileChange = async (e) => {
      
      const currentFile =  e.target.files[0];
      const ext = currentFile.name.split('.').pop();
      const supportedExtension = ['ai', 'dxf', 'cdr'];
    
      if (supportedExtension.includes(ext)) {
        setErrorMsg('');
        setIsLoading(true);
        const modelTranformer = await HandleFileService.convertFile(currentFile, props.converterUri);
        setIsLoading(false);
        const currentState = {
          shapeModel: {name: ext, model: 'file'}, 
          shapeTransformerModel: modelTranformer 
        };
        props.updateApp(currentState);
      } else {
        setErrorMsg(`this extension ${ext} is not supported`)
      }
  };


  return (
    <div className='file-container'>
      {isLoading ? <BackDrop/> : null}
      <label className='file-title'>Your file</label>
      <label className='file-max'>Supported extension ai, cdr and dxf</label>
      <div className='input-file-container'>
      <input type='file' onChange={handleFileChange}/>
      { errorMsg && <ValidationError error={errorMsg} /> }
      </div>
      <Reset resetTittle={'Remove uploads'} resetInputs={resetParams} />
    </div>
  )

}

const mapDispatchToProps = {
  updateApp,
};

const mapStateToProps = (state: any) => ({
  converterUri: state.configData.converterUri,
});

export default connect(mapStateToProps, mapDispatchToProps)(FileContainer);
