import { combineReducers } from 'redux';
import appReducer from './appReducer';
import configReducer from './configReducer';

const rootReducer = combineReducers({
  appData: appReducer ,
  configData: configReducer

});

export default rootReducer;
