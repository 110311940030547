import { Warning16Regular } from "@fluentui/react-icons";
import { ValidationProps } from "../models/ValidationProps";
import "./ValidationError.css";
import React  from 'react';

function ValidationError(props: ValidationProps) {
 
  return (
    <div className="validation-error">
      <Warning16Regular color="red"/>
      <span> {props.error} </span>
    </div>
  );
}

export default ValidationError;
