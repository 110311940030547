import './BackDrop.css';
import React  from 'react';

function BackDrop() {
  return (
    <div className="backdrop-spinner">
      <div className="spinner"></div>
    </div>
  );
}

export default BackDrop;