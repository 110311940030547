import axios, { AxiosResponse } from "axios";
import { ProductCategoryModel } from "../products/models/ProductCategoryModel";
import { ProductParamsModel } from "../products/models/ProductParamsModel";
import { baseUri } from "./Config";

const getProductsByCategory = async (): Promise<ProductCategoryModel[]> => {
    const uri = `${baseUri}/products-by-catagory`

    let response: AxiosResponse;

    try {
      
        response = await axios.post<ProductCategoryModel[]>(uri)
    } catch(err) {
          throw new Error('Can\'t load data server error' )
    }
   
    return  response.data.documents;
}

const getProductParams = async (productId: string): Promise<ProductParamsModel> => {
    const uri = `${baseUri}/products-params`

    let response: AxiosResponse;

    try {
        
        response = await axios.post<ProductParamsModel>(`${uri}/${productId}`)
    } catch(err) {
          throw new Error('Can\'t load data server error' )
    }
   
    return  response.data.documents.length>0 ? response.data.documents[0]: null;
}
  


export const ProductService =  {
    getProductsByCategory,
    getProductParams
}