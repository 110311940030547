import { IModel, IPathMap, paths } from "makerjs";

export class HalfCircle implements IModel {
    public paths: IPathMap = {};
    
  constructor(radius: number) {
    const arc = new paths.Arc([0, 0], radius,0,180); 
    const bottomLine = new paths.Line([[-radius, 0],[radius, 0]])

    this.paths = {arc, bottomLine}
  }
}
  