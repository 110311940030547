import React  from 'react';
import {
  Button,
  FluentProvider,
  Input,
} from "@fluentui/react-components";
import "./AddToCart.css";
import { Cart24Regular } from "@fluentui/react-icons";
import { cuttingFabrikLightTheme } from "../../theme/cuttingFabrikTheme";
import { AddToCardProps } from "../models/AddToCardProps";
import { connect } from "react-redux";
import { updateApp } from "../../store/actions/appActions";
import BackDrop from "../backdrop-spinner/BackDrop";
import { useState } from "react";
import { addOrder } from "../../services/OrderService";


function AddToCart(props: AddToCardProps) {
  const [isLoading, setIsLoading] = useState(false);

  const handleQuantityChanged = (ev: any) => {
   props.updateApp({quantity: ev.target.value })
  }

  const addToCard = async () => {
  setIsLoading(true);
  await addOrder(props.priceModel, props.selectedProduct,props.shapeModel,props.shapeTransformerModel,props.quantity,props.pricePerM2);
  setIsLoading(false);
  }

  
  return (
    <>
    {isLoading ? <BackDrop/> : null}
    <FluentProvider className="add-to-cart-btn" theme={cuttingFabrikLightTheme}>
    <div className="add-to-card-container">
      <div className="quantity-container">
      <label className="quantity">Quantity</label>
        <Input type="number" min={1} onChange={handleQuantityChanged} />
      </div>
        <Button  className="cart-btn" size="large" onClick={addToCard}  appearance="primary" icon={<Cart24Regular />}>
          Add to the Cart
        </Button>
    </div>
    </FluentProvider>
    </>
  );
}

const mapDispatchToProps = {
  updateApp,
};

const mapStateToProps = (state: any) => ({
  selectedProduct: state.appData.selectedProduct,
  shapeModel: state.appData.shapeModel,
  pricePerM2: state.appData.pricePerM2,
  shapeTransformerModel: state.appData.shapeTransformerModel,
  priceModel: state.appData.priceModel,
  quantity: state.appData.quantity,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddToCart);


