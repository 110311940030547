import axios, { AxiosResponse } from "axios";
import { I18nModel } from "../store/models/I18nModel";
import { baseUri } from "./Config";

const getI18n = async (local: string) => {
    const uri = `${baseUri}/i18n`
    let response: AxiosResponse;

    try {
      
        response = await axios.post<I18nModel>(`${uri}/${local}`);
    } catch(err) {
          throw new Error('Can\'t load data server error' )
    }

    return  response.data.documents.length>0 ? response.data.documents[0]: null;
}

export const I18nService =  {
    getI18n,
}