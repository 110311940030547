import axios, { AxiosResponse } from "axios";
import { TransformerModel } from "../shapes/model-handler/model/TransformerModel";
import { baseUri } from "./Config";


const convertFile = async (toConvertfile: File, converterUri: string): Promise<TransformerModel> => {
   

    let response: AxiosResponse;

    try {
        const formData = new FormData();
        
        formData.append('file', toConvertfile);

        response = await axios.post<File>(converterUri,formData)
    } catch(err) {
          throw new Error('Can\'t load data server error' )
    }
   
    return  response.data;
}

const converterUri = async (): Promise<string> => {
    let response: AxiosResponse;

    try {
        response = await axios.get<string>(`${baseUri}/converter-uri`)
    } catch(err) {
          throw new Error('Can\'t load data server error' )
    }
   
    return  response.data.converterUri;
}


  
export const HandleFileService =  {
    convertFile,
    converterUri
}