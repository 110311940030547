import React  from 'react';
import { ResetProps } from '../models/ResetProps';
import './Reset.css';

function Reset(props: ResetProps) {
  return (
    <div className='reset-btn' onClick={() => props.resetInputs()}>
      <p >{props.resetTittle}</p>
    </div>
  )

}

export default Reset;