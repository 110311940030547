import './App.css';
import Main from './components/main/Main';
import React  from 'react';

function App() {
    return( 
        <div> 
           <Main/>  
        </div>
    );
}

export default App;