import { IModel, IPathMap, paths } from "makerjs";

export class HalfSquare implements IModel {
    public paths: IPathMap = {};
    
  constructor(base:number,height: number) {
   
    const baseLine = new paths.Line([[0,0],[base,0]]);
    const rightLine = new paths.Line([[base,0],[0, height]]);
    const leftLine = new paths.Line([[0,0],[0, height]]);
 
    this.paths = { baseLine, rightLine, leftLine }
  }
}
  