import React  from 'react';
import { connect } from 'react-redux';
import { ShapePreviewProps } from '../models/ShapePreviewProps';
import './ShapePreview.css';
import { useEffect, useRef, useState } from 'react';

function ShapePreview(props: ShapePreviewProps) {
  const widthText = useRef<HTMLSpanElement>(null);
  const heightText = useRef<HTMLSpanElement>(null);

  const [arrowHeight,setArrowHeight] = useState(0);
  const [arrowWidth,setArrowWidth] = useState(0);
  const [arrowTop,setArrowTop] = useState(0);
  const [arrowLeft,setArrowLeft] = useState(0);
  const [arrowBottom,setArrowBottom] = useState(0);
  const [spanTextWidth,setSpanTextWidth] = useState(0);
  const [spanTextHeight,setSpanTextHeight] = useState(0);
  const [scrollTop,setScrollTop] = useState(0);

  const updateDimensions = () => {
    const svg = document.getElementById('svgGroup');

    if(svg) {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      setScrollTop(scrollTop);
      const { height, width, top, left, bottom } = svg.getBoundingClientRect();

      console.log({ height, width, top, left, bottom } )
     
      setArrowHeight(height);
      setArrowWidth(width);
      setArrowLeft(left);
      setArrowTop(top);
      setArrowBottom(bottom);
    }

    if(widthText.current) {
      const {width} = widthText.current.getBoundingClientRect();
      setSpanTextWidth(width);
    }

    if(heightText.current) {
      const {height} = heightText.current.getBoundingClientRect();
      setSpanTextHeight(height);
    }
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
  }, [props.model.svg]);

  const formatNumber = (n: number): string => {
    if(n % 1 === 0 ) return n.toString()
    return n.toFixed(2);
}

  return (
    <div id='shape-preview-container' className='shape-preview-container'>
       <img style={{left: arrowLeft - 15, top: arrowTop + scrollTop}}  height={arrowHeight} src='svg/v-arrow.svg'  alt="vertical"/>
       <div className='shape-preview' dangerouslySetInnerHTML={{ __html: props.model.svg }}>
        </div>
        <img style={{left: arrowLeft, top: arrowBottom+ scrollTop + 10 }} width={arrowWidth}  src='svg/h-arrow.svg'  alt="vertical"/>
        <span ref={widthText} className='shape-preview-width' style={{left: arrowLeft + arrowWidth/2 - spanTextWidth/2, top: arrowBottom + scrollTop + 14}}>{formatNumber(props.model.width)} cm</span>
        <span ref={heightText} className='shape-preview-height' style={{left: arrowLeft - 40, top: arrowTop + scrollTop + arrowHeight/2 -spanTextHeight/2}}>{formatNumber(props.model.height)} cm</span>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  model: state.appData.shapeTransformerModel,
});

export default connect(mapStateToProps)(ShapePreview);