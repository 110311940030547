import { Circle } from "./Circle";
import { HalfCircle } from "./HalfCircle";
import { HalfSquare } from "./HalfSquare";
import { Heart } from "./Heart";
import { QuaterCircle } from "./QuaterCircle";
import { Trapezoid } from "./Trapezoid";
import { Triangle } from "./Triangle";

export const Models: any = {
    Circle,
    QuaterCircle,
    HalfCircle,
    Heart,
    Triangle,
    HalfSquare,
    Trapezoid
}