import { cuttingFabrikLightTheme } from '../../theme/cuttingFabrikTheme';
import { CFSelectProps } from '../models/CFSelectModel';
import './CFSelect.css';
import { FluentProvider, Select, useId} from "@fluentui/react-components";
import React  from 'react';

function CFSelect(props: CFSelectProps) {
  const selectId = useId();
  
  return (
    <div className='cf-select-container'>
    <label className='select-label'>{props.cfTitle}</label>

    <FluentProvider theme={cuttingFabrikLightTheme}>
      <Select id={selectId} {...props}>
      {
       props.useFont ?  props.ops.map(opt => <option style={{fontFamily: opt.font, fontSize: '16px'}} value={opt.value}>{opt.label}</option>):
                        props.ops.map(opt => <option value={opt.value}>{opt.label}</option>)
      }
      </Select>
    </FluentProvider>
    </div>
  );
}

export default CFSelect;