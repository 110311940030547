import { createLightTheme } from '@fluentui/react-components';

import type { BrandVariants, Theme } from '@fluentui/react-components';


 const cuttingFabrik: BrandVariants = { 
  10: "#060202",
  20: "#261012",
  30: "#42161D",
  40: "#591A26",
  50: "#711C2E",
  60: "#8A1D37",
  70: "#A31D40",
  80: "#BD1C4A",
  90: "#D81854",
  100: "#E83461",
  110: "#EF5572",
  120: "#F46F83",
  130: "#F98795",
  140: "#FC9DA7",
  150: "#FFB3BA",
  160: "#FFC9CD"
};

 export const cuttingFabrikLightTheme: Theme = {
   ...createLightTheme(cuttingFabrik), 
};

