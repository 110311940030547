import { IModel, IPathMap, paths } from "makerjs";

export class Trapezoid implements IModel {
    public paths: IPathMap = {};

  constructor(leftHeight:number,width: number, rightHeight: number) {
    const halfBase =width/2;
  
    const baseLine = new paths.Line([[-halfBase,0],[halfBase,0]]);
    const rightLine = new paths.Line([[halfBase,0],[halfBase, rightHeight]]);
    const leftLine = new paths.Line([[-halfBase,0],[-halfBase, leftHeight]]);
    const topLine = new paths.Line([[-halfBase, leftHeight],[halfBase, rightHeight]]);
 
    this.paths = { baseLine, rightLine, leftLine, topLine }
  }
}
  