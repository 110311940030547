/* eslint-disable no-eval */
import { ParamsInputModel } from "../../components/models/ParamsInputModel";
import { I18nModel } from "../../store/models/I18nModel";
import { ValidatorModel } from "../models/ValidatorModel";


export class Validator {
    constructor(
        private i18n: I18nModel,
        private  param: ParamsInputModel, 
        private shapeName: string,
        private allParams: ParamsInputModel[],
        private crossParamsValidators: ValidatorModel[],
    ){}

    public validate(): string | null {
         if(this.param.value < this.param.min) 
            return this.resolveValidationMessage('min', null)
         
         if(this.param.value > this.param.max)
            return this.resolveValidationMessage('max', null)

         if(this.param.validators && this.param.validators.length) {
            for(const validator of this.param.validators) {
               const variable = this.resolveVars(validator.name, null);

               const isValid: boolean = eval(variable + validator.validationScript);

               if(!isValid) return this.resolveValidationMessage(validator.name, null)
            }
         }

         if(this.crossParamsValidators && this.crossParamsValidators.length) {
            for(const validator of this.crossParamsValidators) {
               let relatedParams: {paramId: string; value: string}[] = validator.relatedParams?.map(p => {
                  let currentValue = this.allParams.find(pr => pr.paramId === p)?.value.toString() as string;
                  return {paramId: p, value: currentValue }
               }) as {paramId: string; value: string}[]; 

               const variables = this.resolveVars(validator.name, relatedParams);
               
               const isValid: boolean = eval(variables + validator.validationScript);

               if(!isValid) return this.resolveValidationMessage(validator.name, relatedParams)
            }
         }

        return null;
    }

    private resolveValidationMessage(validatorName: string, relatedParams: {paramId: string; value: string}[] | null): string {
        const messages = this.i18n.validators;
        const availableMsg = [messages[`${this.shapeName}.${this.param.paramId}.${validatorName}`],
                              messages[`${this.shapeName}.${validatorName}`],
                              messages[`${validatorName}`] ]

        if(relatedParams) availableMsg.shift()

        const msg = availableMsg.find(v => v!==null && v!==undefined);

       
        return (msg ? this.evaluate(validatorName,msg, relatedParams): 'Validation failed')
    }

    private evaluate(validatorName: string, msg: string, relatedParams: {paramId: string; value: string}[] | null): string {
      const variables = this.resolveVars(validatorName,relatedParams);
    
      const toeval = variables + "`" + msg + "`"

      return eval(toeval);
    }

    private resolveVars(validatorName: string, relatedParams: {paramId: string; value: string}[] | null): string {
      if(validatorName === 'min' || validatorName === 'max' ) {
         return `var ${this.param.paramId}=${this.param.value}; var ${validatorName}=${this.param[validatorName]}; `
       }

       if(relatedParams) {
         return relatedParams.map(p => (`var ${p.paramId}=${p.value}`)).join('; ') +'; '
       }
     
       return `var ${this.param.paramId}=${this.param.value}; `;  
    }

}