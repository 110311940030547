import React  from 'react';
import { useState } from 'react';
import TextParamsInput from '../text-params-input/TextParamsInput';
import './SwitchTextParams.css';
import { SwitchTextParamsProps } from '../models/SwitchTextParamsProps';


function SwitchTextParams(props: SwitchTextParamsProps) {
  const [selectedInputParams, setSelectedInputParams] = useState('Width'); 
 
  const ontoggleSelect = (value: string) => {
    setSelectedInputParams(value)
  }

  const handleWidthValueChanged = (value: number) => {
   props.widthValueChanged(value)
  }

  const handleHeightValueChanged = (value: number) => {
    props.heightValueChanged(value)
  }
  
  return (
   <div className='text-params'>
      <TextParamsInput key='input_w' selected={selectedInputParams === 'Width' ? true : false}
      toogleSelected={ontoggleSelect}
      paramsType={'Width'}
      min={props.textModel.width.min}
      max={props.textModel.width.max} 
      value={props.widthValue} 
      valueChanged={handleWidthValueChanged} />

      <TextParamsInput key='input_h' selected={selectedInputParams === 'Height' ? true : false}
      toogleSelected={ontoggleSelect} paramsType={'Height'}
      min={props.textModel.height.min}
      max={props.textModel.height.max} 
      value={props.heightValue} 
      valueChanged={handleHeightValueChanged} />
   </div>
  );
}

export default SwitchTextParams;