import './TabContainer.css';
import {
  Tab,
  TabList,
  TabListProps,
  FluentProvider,
  webLightTheme,
  shorthands,
  makeStyles,
  SelectTabEvent,
  SelectTabData,
  TabValue,
} from "@fluentui/react-components";


import * as React from "react";
import ShapeContainer from '../shapes-container/ShapesContainer';
import TextContainer from '../text-container/TextContainer';
import FileContainer from '../file-container/fileContainer';

const useStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    ...shorthands.padding("7px", "25%"),
    rowGap: "10px",
  }
});



function TabContainer(props: Partial<TabListProps>) {


  const styles = useStyles();


  const [shapeSrc, setShapeSrc] = React.useState('svg/shape_s.svg');

  const [textSrc, setTextSrc] = React.useState('svg/text.svg');

  const [fileSrc, setFileSrc] = React.useState('svg/upload.svg');

  const [selectedValue, setSelectedValue] = React.useState<TabValue>("Shapes");

  const ShapesPanel = React.memo(() => (<ShapeContainer/>))

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    switch (data.value) {
      case 'Shapes':
        setShapeSrc('svg/shape_s.svg')
        setTextSrc('svg/text.svg')
        setFileSrc('svg/upload.svg')
        setSelectedValue('Shapes')
        break;
      case 'Text':
        setShapeSrc('svg/shape.svg')
        setTextSrc('svg/text_s.svg')
        setFileSrc('svg/upload.svg')
        setSelectedValue('Text')
        break;
      case 'File':
        setShapeSrc('svg/shape.svg')
        setTextSrc('svg/text.svg')
        setFileSrc('svg/upload_s.svg')
        setSelectedValue('File')
        break;
    
      default:
        break;
    }
  };

  return (
    <div>
    <div className={styles.root}>
       <FluentProvider theme={webLightTheme}>
      <TabList {...props} defaultSelectedValue={'Shapes'} onTabSelect={onTabSelect}>
        <Tab value="Shapes" ><span className='tab-value'><img src = {shapeSrc} alt="shapes"/><span>Shapes</span></span></Tab>
        <Tab value="Text"><span className='tab-value'><img src = {textSrc} alt="text"/><span>Text</span></span></Tab>
        <Tab value="File"><span className='tab-value'><img src = {fileSrc} alt="file upload"/><span>File</span></span></Tab>
      </TabList>
      </FluentProvider>
    </div>
      <div>{selectedValue === "Shapes" && <ShapesPanel/>}</div>
      <div>{selectedValue === "Text" && <TextContainer/>}</div>
      <div>{selectedValue === "File" && <FileContainer/>}</div>
      </div>
  );
}

export default TabContainer;