import React  from 'react';
import { connect } from 'react-redux';
import CFSelect from '../cf-select/CFSelect';
import Price from '../price/Price';
import ProductTitle from '../product-title/ProductTitle';
import SelectParams from '../select-params/SelectParams';
import './RightContainer.css';
import { SelectProps } from '../models/SelectProps';
import { useState } from 'react';
import { updateApp } from '../../store/actions/appActions';
import { ProductService } from '../../services/ProductService';

function RightContainer(props: SelectProps) {
  const [ selectedCategory, setSelectedCategory] = useState(props.defaultSelectedCategory);
  const [ selectedProduct, setSelectedProduct] = useState(props.defaultSelectedProduct);

  const handleSelectCategoryChange = (event: any) => {
    const selectedCat = event.target.value;
    setSelectedCategory(selectedCat);

    const {categoryId, categoryName, products} = props.productsCategory.find(pc => pc.categoryId === selectedCat)!;

    setSelectedProduct(products[0].productId)

    ProductService.getProductParams(products[0].productId).then(data => {
      props.updateApp({selectedCategory: { categoryId, categoryName },selectedProduct: products[0], productParms: data})
    })

  };

  const handleSelectProductChange = (event: any) => {
    const selectedValue = event.target.value;
    setSelectedProduct(selectedValue);
    const currentCategory = props.productsCategory.find(pc => pc.categoryId === selectedCategory)!
    const selectedProduct = currentCategory?.products.find(p => p.productId === selectedValue);

    ProductService.getProductParams(selectedValue).then(data => {
      props.updateApp({selectedProduct, productParms: data})
    })

  };


  return (
    <div className='right-container'>
        <ProductTitle/>
        <Price/>
        <CFSelect cfTitle={'Category'} value={selectedCategory} onChange={handleSelectCategoryChange} 
         ops={props.productsCategory.map(pc => ({value: pc.categoryId, label: pc.categoryName}))}/>
        <CFSelect cfTitle={'Product'} value={selectedProduct} onChange={handleSelectProductChange}  
         ops={props.productsCategory.find(pc => pc.categoryId === selectedCategory)!.products.map(p => ({value:p.productId,label: p.name }))}/>
        <SelectParams />
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  productsCategory: state.configData.productCategories,
  defaultSelectedCategory: state.appData.selectedCategory.categoryId,
  defaultSelectedProduct: state.appData.selectedProduct.productId,
});

const mapDispatchToProps = {
  updateApp,
};


export default connect(mapStateToProps, mapDispatchToProps)(RightContainer);