import html2canvas from 'html2canvas';
import { PriceModel } from '../store/models/PriceModel';
import { ProductModel } from '../products/models/ProductModel';
import { ShapeModel } from '../shapes/models/ShapeModel';
import { TransformerModel } from '../shapes/model-handler/model/TransformerModel';
import { baseUri } from './Config';
import axios from 'axios';

const elmToCaptureId = 'shape-preview-container';
const uri = `${baseUri}/orders`;


export const addOrder= async (priceModel: PriceModel, product: ProductModel, shapeModel: ShapeModel, shapeTransformerModel: TransformerModel, quantity: number, pricePerM2: number) => {
    
    const elementToCapture = document.getElementById(elmToCaptureId);
   
    const canvas = await html2canvas(elementToCapture!);

    const currentDate = new Date();

    canvas.toBlob(async (blob) => {
       const formData: FormData =new FormData();
       formData.append('orderId', `O${currentDate.getTime()}`);
       formData.append('orderDate', new Date().toISOString());
       formData.append('productName',product.name);
       formData.append('productId', product.productId);
       formData.append('shapeName',shapeModel.name);
       formData.append('shapeWidth', shapeTransformerModel.width.toString());
       formData.append('shapeHeight', shapeTransformerModel.height.toString());
       formData.append('quantity',quantity.toString());
       formData.append('price', priceModel.currentPrice);
       formData.append('priceM2', pricePerM2.toString());
       formData.append('cuttingPricePerM', priceModel.circumferencePricePerM.toString());
       formData.append('cuttingPrice', priceModel.circumferencePrice.toString());
       formData.append('screenshot', blob!);

       try {
        await axios.post(uri, formData)
     } catch(err) {
           throw new Error('Can\'t ad this order' )
     }
       
    })

   
   

}