import { calculatePrice } from '../../products/product-pricing/ProductPricing';
import { UPDATE_APP } from '../actions/types';
import { ApplicationStateModel } from '../models/ApplicationStateModel';
import { PriceModel } from '../models/PriceModel';

const initialState: ApplicationStateModel = {
  productParms: null,
  selectedCategory: null,
  selectedProduct: null,
  shapeModel: null,
  isExc: false,
  shapeTransformerModel: null,
  pricePerM2: null,
  priceModel: null,
  quantity: 1,
};

const appReducer = (state = initialState , action:any) => {
  switch (action.type) {
    case UPDATE_APP:
      state = {...state, ...action.payload};
      const priceModel: PriceModel = calculatePrice(state.productParms!, state.shapeModel!, state.shapeTransformerModel!, state.isExc, state.quantity); 
      return {
        ...state,
        currentPrice: priceModel.currentPrice,
        priceModel,
        pricePerM2: state.isExc ? state.productParms!.settings.pricePerM2ExcludingTax : state.productParms!.settings.pricePerM2IncludingTax
      };
    default:
      return state;
  }
};

export default appReducer;
