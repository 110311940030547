import { IModel, IPathMap, paths } from "makerjs";

export class QuaterCircle implements IModel {
    public paths: IPathMap = {};
    
  constructor(radius: number) {
    const arc = new paths.Arc([0, 0], radius,0,90); 
    const leftLine = new paths.Line([[0, 0],[0, radius]])
    const bottomLine = new paths.Line([[0, 0],[radius, 0]])

    this.paths = {arc, leftLine,bottomLine}
  }
}
  