import { useEffect, useState } from "react";
import React  from 'react';
import "./Main.css";
import { updateApp } from "../../store/actions/appActions";
import { setConverterUri, setI18n, setProductCategory, setShapes, setTextShapes } from "../../store/actions/configActions";
import { connect } from "react-redux";
import LeftContainer from "../left-container/LeftContainer";
import RightContainer from "../right-container/RightContainer";
import { ProductCategoryModel } from "../../products/models/ProductCategoryModel";
import { AppProps } from "../models/AppProps";
import { transformModel } from "../../shapes/model-handler/ModelTransformerHandler";
import { ProductService } from "../../services/ProductService";
import { ShapeModel } from "../../shapes/models/ShapeModel";
import { ShapeService } from "../../services/ShapeService";
import { I18nModel } from "../../store/models/I18nModel";
import { I18nService } from "../../services/I18nService";
import { local } from "../../services/Config";
import { TextShapeModel } from "../../shapes/models/TextShapeModel";
import { TextShapeService } from "../../services/TextShapeService";
import BackDrop from "../backdrop-spinner/BackDrop";
import { HandleFileService } from "../../services/HandleFileService";

function Main(props: AppProps) {
  const [apiError, setApiError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const createInitialState = async () => {
    try{
      const productsCategory: ProductCategoryModel[] = await ProductService.getProductsByCategory();
      const shapes: ShapeModel[] = await  ShapeService.getAllShapes();
      const i18n: I18nModel = await I18nService.getI18n(local);
      const textShapes: TextShapeModel[] = await TextShapeService.getAllTextShapes();
      const converterUri: string = await HandleFileService.converterUri();

      props.setProductCategory(productsCategory);
      props.setShapes(shapes)
      props.setI18n(i18n);
      props.setTextShapes(textShapes);
      props.setConverterUri(converterUri);

      const { categoryId, categoryName, products } = productsCategory[0];
      const selectedCategory = { categoryId, categoryName };
      const selectedProduct = products[0];
      const shapeModel: ShapeModel = shapes[0];
  
      const shapeTransformerModel = transformModel(shapeModel, null);
      const productParms = await ProductService.getProductParams(
        selectedProduct.productId
      );
      props.updateApp({
        productParms,
        selectedCategory,
        selectedProduct,
        shapeModel,
        isExc: false,
        shapeTransformerModel,
      });
      setIsLoading(false);
    } catch(err: any) {
      console.log(err)
      setApiError(true)
    }
  
  };

  useEffect(() => {
    createInitialState();
  });

  if (isLoading) return <BackDrop />;

  if (apiError) return <p>Can't load data server error</p>;

  return (
    <div className="container">
      <LeftContainer />
      <RightContainer />
    </div>
  );
}

const mapDispatchToProps = {
  updateApp,
  setProductCategory,
  setShapes,
  setI18n,
  setTextShapes,
  setConverterUri
};

export default connect(null, mapDispatchToProps)(Main);
