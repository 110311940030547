import React  from 'react';
import { connect } from 'react-redux';
import Tax from '../tax/Tax';
import './Price.css';
import { PriceProps } from '../models/PriceProps';

function Price(props: PriceProps) {
  return (
    <div className='price-container'>
      <div className='tax-price-container'>
      <Tax />
      <p className='price'>€ {props.price}</p>
      </div>
      <p className='price-per-m2'>1 m² = € {props.pricePerM2}</p>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  price: state.appData.currentPrice,
  pricePerM2: state.appData.pricePerM2
});

export default connect(mapStateToProps)(Price);

