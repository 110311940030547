import React  from 'react';
import PriceLogger from '../log-price/PriceLogger';
import ShapePreview from '../shape-preview/ShapePreview';
import './LeftContainer.css';

function LeftContainer() {
  return (
    <div className='left-container'>
        <ShapePreview/>
        <PriceLogger />
    </div>
  );
}

export default LeftContainer;