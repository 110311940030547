import React  from 'react';
import { cuttingFabrikLightTheme } from '../../theme/cuttingFabrikTheme';
import { ShapeParamsInputProps } from '../models/ShapeParamsInputProps';
import ValidationError from '../validation-error/ValidationError';
import './ShapeParamsInput.css';
import {
  Input,
  FluentProvider,
} from "@fluentui/react-components";



function ShapeParamsInput(props: ShapeParamsInputProps) {

  const handleInputChanged = (ev: any) => {
    props.onParamsValueChanged({...props.param, value: ev.target.value })
  }



 
  return (
    <div className='params'>
    <label className='params-type'>{props.param.type} (cm)</label>
    <label className='params-min-max'>[between {props.param.min} cm and {props.param.max} cm]</label>
    <FluentProvider theme={cuttingFabrikLightTheme}>
    <Input onChange={handleInputChanged} value={props.param.value.toString()}   className='input-p'/>
    </FluentProvider>
    { props.param.errorMsg && <ValidationError error={props.param.errorMsg} /> }
    </div>
  );
}


export default ShapeParamsInput;