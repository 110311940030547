import { IModel, IPathMap, paths } from "makerjs";

export class Heart implements IModel {
    public paths: IPathMap = {};
    
  constructor(height: number) {
    const half = height/2;
    const quater= half/2;
    const radius = height/(Math.sqrt(2) * 2)
    const firstRightArc = new paths.Arc([quater,quater], radius, -45, 0);
    const secondRightArc = new paths.Arc([quater, quater], radius, 0, 135);
    const firstLeftArc = new paths.Arc([-quater,quater], radius, 45, 180);
    const secondLeftArc  = new paths.Arc([-quater,quater], radius, 180, 225);
    const rightLine = new paths.Line([[half,0],[0,-half]]);
    const leftLine = new paths.Line([[-half,0],[0,-half]]);
 
    this.paths = {firstRightArc, secondRightArc, firstLeftArc, secondLeftArc, rightLine, leftLine }
  }
}
  