import React  from 'react';
import { connect } from 'react-redux';
import { ProductTitleProps } from '../models/ProductTitleProps';
import './ProductTitle.css';

function ProductTitle(props: ProductTitleProps) {
  return (
    <div >
      <p className='title'>{props.title}</p>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  title: state.appData.selectedProduct.name,
});

export default connect(mapStateToProps)(ProductTitle);

