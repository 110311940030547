import React  from 'react';
import { connect } from "react-redux";
import { PriceLoggerProps } from "../models/PriceLoggerProps";
import "./PriceLogger.css";

function PriceLogger(props: PriceLoggerProps) {
 
  return (
      
        <div className="price-logger-container">
          <h5>Area Price</h5>
          <span><span className="elm-title">Width (cm) :</span> {props.shapeTransformer.width}</span> <span><span className="elm-title">height (cm) :</span> {props.shapeTransformer.height} </span> <span><span className="elm-title">Price M2 : </span>{props.priceM2}</span> <span><span className="elm-title">Area Price :</span> {props.priceModel.areaPrice}</span>
          <h5>Circumference Price</h5>
          <span><span className="elm-title">Circumference (cm) : </span> {props.shapeTransformer.pathLength} </span> <span><span className="elm-title">Circumference Price Per M:</span> {props.priceModel.circumferencePricePerM}</span> <span><span className="elm-title">Circumference Price: </span> {props.priceModel.circumferencePrice}</span>
          <h5>Total Price: {props.priceModel.price} </h5>
          <h5>Minimum Price: {props.priceModel.minimumPrice}</h5>
          <h5>Price: {props.priceModel.currentPrice}</h5>
         
        </div>
  );
}

const mapStateToProps = (state: any) => ({
  shapeTransformer: state.appData.shapeTransformerModel,
  priceModel: state.appData.priceModel,
  priceM2: state.appData.pricePerM2
});

export default connect(mapStateToProps)(PriceLogger);



