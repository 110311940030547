import {
  Badge,
  FluentProvider,
} from "@fluentui/react-components";
import "./Tax.css";
import React  from 'react';

import { cuttingFabrikLightTheme } from "../../theme/cuttingFabrikTheme";
import { useState } from "react";
import { updateApp } from "../../store/actions/appActions";
import { connect } from "react-redux";
import { TaxProps } from "../models/TaxProps";

function Tax(props: TaxProps) {
  const [isExcl, setIsExcl] = useState(false)
  const [isInkl, setIsInkl] = useState(true)

  const toogleExcl = () => {
   if(!isExcl) {
    setIsExcl(true)
    setIsInkl(false)
    props.updateApp({isExc: true})
   }
  }

  const toogleInkl = () => {
    if(!isInkl) {
      setIsInkl(true)
      setIsExcl(false)
      props.updateApp({isExc: false})
     }
  }

  return (
      
        <div className="tax-container">
          <FluentProvider theme={cuttingFabrikLightTheme}>
        <Badge onClick={toogleExcl} size="large" appearance= {isExcl ? "filled" : "ghost"}>
          <div className="tax-text-container">
           <img width='14px' height ='14px'  src = { isExcl ? 'svg/select.svg' : 'svg/not_checked.svg' }  alt="tax" /> 
           <span style={isExcl ? {color: 'white'} : {color : 'black'} }>excl. MwST.</span>
           </div>
        </Badge>
        </FluentProvider>
        <FluentProvider theme={cuttingFabrikLightTheme}>
        <Badge onClick={toogleInkl} size="large" appearance= {isInkl ? "filled" : "ghost"}> 
        <div className="tax-text-container">
          <span style={isInkl? {color: 'white'} : {color : 'black'} }>inkl. MwST.</span>  
          <img width='14px' height ='14px'  src = { isInkl ? 'svg/select.svg' : 'svg/not_checked.svg' }  alt="tax"  />
        </div>
        </Badge>
        </FluentProvider>
        </div>
  );
}

const mapDispatchToProps = {
  updateApp,
};

export default connect(null, mapDispatchToProps)(Tax);
