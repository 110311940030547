import { Font } from "opentype.js";
import { svg_style } from "../constants/shape.const";
import { ShapeModel } from "../models/ShapeModel";
import { loadModel } from "./ModelLoader";
import { TransformerModel } from "./model/TransformerModel";

const makerjs = require('makerjs');

const transform = (modelName: string, params: any[]): TransformerModel => {
    const model = loadModel(modelName, params);

    const makerjs = require('makerjs');

    const unit = makerjs.unitType.Centimeter;

    const pathLength = makerjs.measure.modelPathLength(model);

    const modelMeasurements = makerjs.measure.modelExtents(model);

    const height = modelMeasurements.height;

    const width = modelMeasurements.width;

    const svg = makerjs.exporter.toSVG(makerjs.model.convertUnits(model, unit), {stroke: svg_style.stroke, strokeWidth:svg_style.strokeWidth, fill: svg_style.fill });


return {
   svg,
   pathLength,
   height,
   width
}
}

export const transformModel= (shapeModel: ShapeModel, params: number[] | null): TransformerModel | null =>  {
    if(shapeModel) {
        if(!params) return transform(shapeModel.model as string, shapeModel.params!.map(p => p.default))

        return transform(shapeModel.model as string, params); 
    }

   return null;
}

export const transformTextModel= (text: string, desiredLength: number, isWidth: boolean, font: Font): TransformerModel | null =>  {
    const firstRender = new makerjs.models.Text( font, text, desiredLength * 10 );
    const enclosingRectangle = makerjs.measure.modelExtents( firstRender );
    const actualLength = isWidth ? enclosingRectangle.width : enclosingRectangle.height;
    const ratio = actualLength / desiredLength;
    const fontSize = desiredLength / ratio;
   
    return transform('Text', [font, text.trim(), fontSize * 10]);
}
