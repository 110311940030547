import { SET_CONVERTER_URI, SET_I18N, SET_PRODUCT_CATEGORY, SET_SHAPES, SET_TEXT_SHAPES } from "../actions/types";

const initialState = {productCategories: [], shapes: null, i18n: null,converterUri:null}

const configReducer = (state = initialState , action:any) => {
  switch (action.type) {
    case SET_PRODUCT_CATEGORY:
      return {
        ...state,
        productCategories: action.payload
      };
    case SET_SHAPES:
        return {
          ...state,
          shapes: action.payload
        };
    case SET_I18N:
          return {
            ...state,
            i18n: action.payload
          };
    case SET_TEXT_SHAPES:
            return {
              ...state,
              textShapes: action.payload
            };
     case SET_CONVERTER_URI:
              return {
                ...state,
                converterUri: action.payload
              };
    default:
      return state;
  }
};

export default configReducer;
