import React  from 'react';
import { useState } from "react";
import Shape from "../shape/Shape";
import "./ShapesContainer.css";
import ShapeParamsInputContainer from "../shape-params-input-container/ShapeParamsInputContainer";
import { ShapeModel } from "../../shapes/models/ShapeModel";
import { ShapeContainerProps } from "../models/ShapeContainerProps";
import { connect } from "react-redux";

function ShapeContainer(props: ShapeContainerProps) {
  const [shapeModel, setShapeModel] = useState<ShapeModel>(props.currentShapeModel.name !== 'text' ? props.currentShapeModel : props.shapes[0]);
  
  return (
    <div>
    <div className="shape-container">
      {
      props.shapes.map((s,index) => (
        <Shape  key={index} shapeModel={s} shapeModelChanged={(model: any) => setShapeModel(model)}/>
      ))
      }
    </div>
    <ShapeParamsInputContainer shapeModel={shapeModel} />
    </div>
    
  );
}


const mapStateToProps = (state: any) => ({
  currentShapeModel: state.appData.shapeModel,
  shapes: state.configData.shapes,
});

export default connect(mapStateToProps)(ShapeContainer);