import React  from 'react';
import { ShapePropsModel } from '../models/ShapePropsModel';
import './Shape.css';
import { updateApp } from '../../store/actions/appActions';
import { connect } from 'react-redux';
import { transformModel } from '../../shapes/model-handler/ModelTransformerHandler';

function Shape(props: ShapePropsModel) {

  const handleShapeClicked =  () => {
    props.shapeModelChanged(props.shapeModel); 
    props.updateApp({shapeModel: props.shapeModel, shapeTransformerModel: transformModel(props.shapeModel, null)})
  };
  
  return (
      <button onClick={ handleShapeClicked } className='shape'>
        <img src = {props.shapeModel.svg} alt="shapes"/>
      </button>
  );
}

const mapDispatchToProps = {
  updateApp,
};

export default connect(null, mapDispatchToProps)(Shape);