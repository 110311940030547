import { IModel } from "makerjs"
import { Models } from "../shapes-models/models";

export const loadModel = (modelName: string, params: number[]): IModel => {
    if(modelName === undefined || modelName === null) throw new Error('Invalid model name')

    const makerjs = require('makerjs');

    if(makerjs.models[modelName] === undefined) {
        if(Models[modelName] === undefined) throw new Error('this model does not exist')
        else return new Models[modelName](...params)

    } 
        
    return new makerjs.models[modelName](...params) 
}