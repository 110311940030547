import axios, { AxiosResponse } from "axios";
import { TextShapeModel } from "../shapes/models/TextShapeModel";
import opentype from "opentype.js";
import { baseUri } from "./Config";

const getAllTextShapes = async () => {
    const uri = `${baseUri}/text-shape`
    let response: AxiosResponse;

    try {
     
        response = await axios.post<TextShapeModel[]>(uri);

      for(const ts of response.data.documents) {
        const fontReader = await fetch(ts.fontPath);
        const fontData = await fontReader.arrayBuffer();
        ts.font =  opentype.parse(fontData);
      }
    } catch(err) {
      console.log(err)
          throw new Error('Can\'t load data server error' )
    }
   
    return  response.data.documents;
}

export const TextShapeService =  {
    getAllTextShapes,
}