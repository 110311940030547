export const text_params = {
    name: 'text',
    svg: 'svg/text.svg',
    params: [
        {
            type: 'Length',
            min:9,
            max:99,
        },
        {
            type: 'Width',
            min:9,
            max:99,
        }
    ]
} 

export const file_params = {
    name: 'file',
    svg: 'svg/upload.svg',
    maxFiles:3
} 

export const svg_style = {
    stroke:'#E41658', 
    strokeWidth:'1px', 
    fill: '#ffffff'
}



