import { IModel, IPathMap, paths } from "makerjs";

export class Circle implements IModel {
    public paths: IPathMap = {};
    
  constructor(radius: number) {
       const circle = new paths.Circle(radius);
       this.paths['circle'] = circle;
  }
}
  
