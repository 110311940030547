import { FluentProvider, Input, useId } from '@fluentui/react-components';
import './TextContainer.css';
import SwitchTextParams from '../switch-text-params/SwitchTextParams';
import CFSelect from '../cf-select/CFSelect';
import Reset from '../reset/Reset';
import { TextContainerProps } from '../models/TextContainerProps';
import { connect } from 'react-redux';
import { cuttingFabrikLightTheme } from '../../theme/cuttingFabrikTheme';
import FontFaceBuilder from '../font-face-builder/FontFace';
import { transformTextModel } from '../../shapes/model-handler/ModelTransformerHandler';
import { updateApp } from '../../store/actions/appActions';
import { useEffect, useState } from 'react';
import React  from 'react';



function TextContainer(props: TextContainerProps) {
  const inputId = useId("input");

  const [currentText, setCurrentText] = useState<string>('Hello');
  const [currentFont, setCurrentFont] = useState<number>(0);
  const [widthValue, setWidthValue] = useState(props.textShapes[0].width.default);
  const [heightValue, setHeightValue] = useState<number>(0);
  const [isWidth,setIsWidth]= useState(true);

  const initState = () => {
    const currentState = {
      shapeModel: {name: 'text', model: 'Text'}, 
      shapeTransformerModel: transformTextModel(currentText, widthValue , true, props.textShapes[currentFont].font)};
      setHeightValue(currentState.shapeTransformerModel?.height!);
      props.updateApp(currentState);
  }

  useEffect(() => {
    initState();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  
  const handleFontChanged = (evt: any) => {
    const index = evt.target.value;
    const currentTextShape = props.textShapes[index];
    setCurrentFont(index)

    props.updateApp({
      shapeModel: {name: 'text', model: 'Text'}, 
      shapeTransformerModel: transformTextModel(currentText, currentTextShape.width.default, true, currentTextShape.font)})
  }

  const handleInputChanged = (evt: any) => {
    const value = evt.target.value;
    setCurrentText(value);

    props.updateApp({
      shapeModel: {name: 'text', model: 'Text'}, 
      shapeTransformerModel: transformTextModel(value,isWidth ? widthValue : heightValue, isWidth, props.textShapes[currentFont].font)})
  }

  const handleWidthValueChanged = (value: number) => {
    setIsWidth(true);
    setWidthValue(value);
    const currentState = {
      shapeModel: {name: 'text', model: 'Text'}, 
      shapeTransformerModel: transformTextModel(currentText, value , true, props.textShapes[currentFont].font)};
      setHeightValue(currentState.shapeTransformerModel?.height!);
    props.updateApp(currentState);
  }
  const handleHeightValueChanged = (value: number) => {
    setIsWidth(false);
    setHeightValue(value);
    const currentState = {
      shapeModel: {name: 'text', model: 'Text'}, 
      shapeTransformerModel: transformTextModel(currentText, value , false, props.textShapes[currentFont].font)}
    setWidthValue(currentState.shapeTransformerModel?.width!)
    props.updateApp(currentState);
  }

  const resetParams = () => {
   setCurrentFont(0);
   setCurrentText('Hello');
   setWidthValue(props.textShapes[0].width.default)
   setIsWidth(true);
   initState();
  }

  return (

      <div className='text-container' >
      {props.textShapes.map(ts => (<FontFaceBuilder font={ts.fontName} url={ts.fontPath} />))}

      <CFSelect cfTitle={'Font'} value={currentFont} onChange={handleFontChanged} useFont={true} ops={props.textShapes.map((ts, index) => ({ value: index.toString(), label: ts.fontName, font: ts.fontName }))} />
      <label>Text</label>
      <FluentProvider theme={cuttingFabrikLightTheme}>
        <Input className='input-p' onChange={handleInputChanged} value={currentText}  id={inputId} />
      </FluentProvider>
      <SwitchTextParams textModel={props.textShapes[currentFont]} widthValue={widthValue.toString()} heightValue={heightValue.toString()} widthValueChanged={handleWidthValueChanged} heightValueChanged={handleHeightValueChanged}></SwitchTextParams>
      <Reset resetTittle={'Reset values'} resetInputs={resetParams} />
    </div>

 
  )

}

const mapDispatchToProps = {
  updateApp,
};

const mapStateToProps = (state: any) => ({
  textShapes: state.configData.textShapes,
});

export default connect(mapStateToProps, mapDispatchToProps)(TextContainer);
