import React  from 'react';
import { useEffect, useState } from 'react';
import { ShapeParamsContainerProps } from '../models/ShapeParamsContainerProps';
import Reset from '../reset/Reset';
import ShapeParamsInput from '../shape-params-input/ShapeParamsInput';
import './ShapeParamsInputContainer.css';
import { transformModel } from '../../shapes/model-handler/ModelTransformerHandler';
import { updateApp } from '../../store/actions/appActions';
import { connect } from 'react-redux';
import { ParamsInputModel } from '../models/ParamsInputModel';
import { Validator } from '../../shapes/validator/ShapeValidator';




function ShapeParamsInputContainer(props: ShapeParamsContainerProps) {
  const initState = props.shapeModel!.params!.map((param, index) => ({...param, index, value: param.default, errorMsg: null}))

  const [params, setParams] = useState<ParamsInputModel[]>(initState)

  useEffect(() => {
   setParams(props.shapeModel!.params!.map((param, index) => ({...param, index, value: param.default, errorMsg: null})))
  }, [props.shapeModel]);


  const handleParamsChanged = (param: ParamsInputModel) => {
       const newParams = [...params]

       newParams[param.index] = {...param };
       console.log(props.i18n)
       const errorMsg = new Validator(
        props.i18n,
        param,
        props.shapeModel.name,
        newParams,
        props.shapeModel.validators!,
        ).validate();

       newParams[param.index] = {...param, errorMsg };

      setParams(newParams);

    if(!errorMsg) props.updateApp({shapeTransformerModel: transformModel(props.shapeModel, newParams.map(p => Number(p.value)))})
  }


  const resetParams = () => {
    const init = props.shapeModel!.params!.map((param, index) => ({...param, index, value: param.default, errorMsg: null}))
    setParams(init)
   
    props.updateApp({shapeTransformerModel: transformModel(props.shapeModel, init.map(p => Number(p.value)))})
  }

  return (
    <div className='params-container'>
        {
        params.map((p) =>  (
        <ShapeParamsInput key={p.index} param={p}  onParamsValueChanged={handleParamsChanged} />
        ))
      }

      <Reset resetTittle={'Reset values'} resetInputs={resetParams} />
    </div>
  );
}

const mapDispatchToProps = {
  updateApp,
};

const mapStateToProps = (state: any) => ({
  i18n: state.configData.i18n,
});

export default connect(mapStateToProps, mapDispatchToProps)(ShapeParamsInputContainer);