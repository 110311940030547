import styled from 'styled-components';
import './FontFace.css';
import React  from 'react';
import { FontFaceProps } from '../models/FontFaceProps';


function FontFaceBuilder(props: FontFaceProps) {

  const FontFaceElement = styled.div`
  @font-face {
    font-family: ${props.font};
    src: url(${props.url});
  }
`;

  return (
    <FontFaceElement />
  );
}

export default FontFaceBuilder;

