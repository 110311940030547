import { ProductCategoryModel } from '../../products/models/ProductCategoryModel';
import { ShapeModel } from '../../shapes/models/ShapeModel';
import { TextShapeModel } from '../../shapes/models/TextShapeModel';
import { I18nModel } from '../models/I18nModel';
import { SET_CONVERTER_URI, SET_I18N, SET_PRODUCT_CATEGORY, SET_SHAPES, SET_TEXT_SHAPES } from './types';

export const setProductCategory = (data: ProductCategoryModel[]) => {
  return {
    type: SET_PRODUCT_CATEGORY,
    payload: data,
  };
};

export const setShapes = (data: ShapeModel[]) => {
  return {
    type: SET_SHAPES,
    payload: data,
  };
};

export const setI18n = (data: I18nModel) => {
  return {
    type: SET_I18N,
    payload: data,
  };
};

export const setTextShapes = (data: TextShapeModel[]) => {
  return {
    type: SET_TEXT_SHAPES,
    payload: data,
  };
};

export const setConverterUri = (data: string) => {
  return {
    type: SET_CONVERTER_URI,
    payload: data,
  };
};
