import axios, { AxiosResponse } from "axios";
import { ShapeModel } from "../shapes/models/ShapeModel";
import { baseUri } from "./Config";


const getAllShapes = async () => {
    const uri = `${baseUri}/shapes`;
    
    let response: AxiosResponse;

    try {
       
        response = await axios.post<ShapeModel[]>(uri);
    } catch(err) {
          throw new Error('Can\'t load data server error' )
    }
   
    return  response.data.documents;
}

export const ShapeService =  {
    getAllShapes,
}