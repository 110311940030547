import { TransformerModel } from "../../shapes/model-handler/model/TransformerModel";
import { ShapeModel } from "../../shapes/models/ShapeModel";
import { PriceModel } from "../../store/models/PriceModel";
import { ProductParamsModel } from "../models/ProductParamsModel";
import { ShapePriceDetails } from "../models/ShapePriceDetails.model";

const formatPrice = (price: number): string => {
     if(price % 1 === 0 ) return price.toString()
     return price.toFixed(2);
}

export const calculatePrice = (productParms: ProductParamsModel, shapeModel: ShapeModel, shapeTransformerModel: TransformerModel, isExc: boolean, quantity: number): PriceModel => {
   const pricePerM2 = isExc ? productParms.settings.pricePerM2ExcludingTax : productParms.settings.pricePerM2IncludingTax;
   
   const shapePriceDetails :ShapePriceDetails = productParms.pricingGroup.shape_settings[shapeModel.name];

   const minimumPrice = Math.max(productParms.settings.minimumPrice, shapePriceDetails ? shapePriceDetails.selling_price_minimum : 0);
   const circumferencePricePerM = shapePriceDetails ?  productParms.pricingGroup.sawing_method_settings[shapePriceDetails.sawing_method].purchase_price_per_m : 0;

   const areaPrice = pricePerM2 * ((shapeTransformerModel.width * shapeTransformerModel.height)/ 10000)



   const circumferencePrice = (circumferencePricePerM * shapeTransformerModel.pathLength) / 100;

   const price = areaPrice + circumferencePrice;

    const priceModel: PriceModel = {
       minimumPrice,
       circumferencePricePerM,
       circumferencePrice,
       areaPrice,
       price,
       currentPrice: price > minimumPrice ? formatPrice(price * quantity) : formatPrice(minimumPrice * quantity)

    }

     return priceModel;


}

