import './TextParamsInput.css';
import {
  Input,
  FluentProvider,
} from "@fluentui/react-components";
import { TextParamsInputProps } from '../models/TextParamsInputProps';
import { cuttingFabrikLightTheme } from '../../theme/cuttingFabrikTheme';
import React  from 'react';

function TextParamsInput(props: TextParamsInputProps) {

  const handleClickImg = () => {
    props.toogleSelected(props.paramsType)
  } 

  const handleValueChanged = (evt: any) => {
    props.valueChanged(evt.target.value);
  }

  return (
    <div className='params'>
    <div className='params-name'>
    <img onClick={handleClickImg} src = {props.selected ? 'svg/checked.svg' : 'svg/not_checked.svg' } alt="select"/>
    <label className='params-type' style={props.selected ? {color: '#E41658'} : {color: 'var(--dark, #242424)'}  }>{props.paramsType} (cm)</label>
    </div>
    <label className='params-min-max'>[between {props.min} cm and {props.max} cm]</label>
    <FluentProvider theme={cuttingFabrikLightTheme}>
    <Input className='input-p' onChange={handleValueChanged} value={props.value}  disabled={!props.selected}/>
    </FluentProvider>
    </div>
  );
}

export default TextParamsInput;